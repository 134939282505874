import axios from 'axios'

// 创建axios实例
const service = axios.create({
  baseURL: 'https://work.lockpy.vip', // api 的 base_url
  timeout: 150000, // 请求超时时间
})

// http request 拦截器
service.interceptors.request.use(
  (config) => {
    let token = window.localStorage.getItem('token') || ''

    // alert('进入请求拦截器 当前请求为【' + JSON.stringify(config) + '】')
    if (token != '') {
      config.headers['token'] = token
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
// http response 拦截器 208和209调换了下位置
service.interceptors.response.use(
  (response) => {
    // alert(
    //   '进入响应拦截器 请求的URL为: ' +
    //     response.config.url +
    //     ' 请求方法为: ' +
    //     response.config.method +
    //     ' 响应数据: ' +
    //     JSON.stringify(response.data)
    // )
    if (response.data.code == 209) {
      // debugger
      // 替换# 后台获取不到#后面的参数
      let url = window.location.href.replace('#', 'guiguoa')
      window.location =
        'https://work.lockpy.vip/admin/wechat/authorize?returnUrl=' + url
    } else {
      if (response.data.code == 200) {
        return response.data
      } else {
        // 209没有权限 系统会自动跳转授权登录的，已在App.vue处理过，不需要提示
        if (response.data.code != 208) {
          // alert(response.data.message || 'error')
        }
        return Promise.reject(response)
      }
    }
  },
  (error) => {
    return Promise.reject(error.response) // 返回接口返回的错误信息
  }
)

export default service
